<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>元数据管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/coalSystemManage-systemManage' }">
        {{crumbA}}
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/coalSystemManage-subSystemManage' }">
        {{crumbB}}
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        {{crumbC}}
      </el-breadcrumb-item>
    </el-breadcrumb>

    <!--    <span style="font-size: 12px">项目系统概述：</span>-->
    <!--    <div class="coalblock" v-for="item in options" :key="item.title">-->
    <!--      <span style="font-size: 12px; color: #9196a1">{{ item.title }}</span>-->
    <!--      <br />-->
    <!--      <span style="font-size: 24px">{{ item.num }}</span>-->
    <!--    </div>-->
    <!--    <coal-over-view></coal-over-view>-->
    <over-view-of-sub-system :table-data="tableData" :total="total" v-if="overViewFlag"></over-view-of-sub-system>

    <el-form :inline="true">
      <el-form-item>
        <el-button v-if="isAuth('equipment:add') && setting.backups" type="primary" @click="addEquipment">增加设备</el-button>
        <el-button v-if="isAuth('equipment:delete') && setting.batchremove" type="danger" @click="deleteEquipment()">批量删除设备</el-button>
      </el-form-item>
      <span style="font-size: 16px">搜索：</span>
      <el-cascader
          :options="parentOption"
          separator=" | "
          style="width: 400px"
          :props="props"
          placeholder="请选择设备"
          clearable
          ref="cascade"
          filterable
          @change="handleSearch()"
      ></el-cascader>
    </el-form>
    <br>
    <el-table
        :data="tableData"
        style="min-width: 100%"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
    >
      <el-table-column
          type="selection"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          label="序号"
          type="index"
          min-width="5%"
          align="center">
        <template slot-scope="scope">
          <span>{{(page - 1) * size + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="id"
          label="设备id"
          header-align="center"
          align="center"
          min-width="5%"
          v-if="false"
      >
      </el-table-column>
      <el-table-column
          prop="name"
          label="设备名称"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      
	  <el-table-column
	      prop="subEquipmentCount"
	      label="附属设备个数"
	      header-align="center"
	      align="center"
	      min-width="10%"
	  >
	  </el-table-column>
	  <el-table-column
	      prop="subEquipmentBqmess"
	      label="标签信息"
	      header-align="center"
	      align="center"
	      min-width="10%"
	  >
	  </el-table-column>
      <el-table-column
          label="操作"
          header-align="center"
          align="center"
          min-width="10%"
      >
        <template slot-scope="scope">
          <!--          <el-button-->
          <!--              type="text"-->
          <!--              size="small"-->
          <!--              @click="handleCheck(scope.row)"-->
          <!--              :disabled="scope.row.subSystemCount == 0?true:false"-->
          <!--          >查看-->
          <!--          </el-button>-->
          <el-button
              type="text"
              size="small"
              @click="handleCheck(scope.row)"
          >查看
          </el-button>
          <el-button
              v-if="isAuth('equipment:update')"
              type="text"
              size="small"
              @click="updateHandle(scope.row)"
          >修改</el-button>
          <el-button
              v-if="isAuth('equipment:delete')"
              type="text"
              size="small"
              style="color: red"
              @click="deleteEquipment(scope.row.id)"
          >删除</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
          prop="remark"
          label="备注"
          header-align="center"
          align="center"
          min-width="20%"
      >
      </el-table-column>
    </el-table>

    <add-equipment
        v-if="equipmentDialog"
        ref="addEquipment"
        @update="update"
    ></add-equipment>

    <update-equipment
        v-if="updateVisible"
        ref="updateEquipment"
        @refreshDataList="initDataWithGlobalVar"
    ></update-equipment>

    <!-- 分页区域 -->
    <div style="display: flex; justify-content: flex-end">
      <el-pagination
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="page"
          :page-size="size"
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import addEquipment from "./addEquipment";
import coalOverView from "./coalOverView";
import updateEquipment from "./updateEquipment";
import OverViewOfSubSystem from "./overViewOfSubSystem";

export default {
  name: "deviceManage",
  data() {
    let that = this;
    return {
      name:'项目设备',
      dataListLoading: false,
      dataListSelections: [],
      equipmentDialog: false,
      parentOption: [],
      // options: [
      //   {
      //     title: "总数据量",
      //     num: "1.2T",
      //   },
      //   {
      //     title: "系统个数",
      //     num: "8个",
      //   },
      //   {
      //     title: "子系统系统个数",
      //     num: "12个",
      //   },
      //   {
      //     title: "设备个数",
      //     num: "125",
      //   },
      // ],
      tableData: [],
      page: 1, //当前第几页
      size: 10, //当前每页页数
      total: 0, //全部数据量

      currOperator: '',
      currOperatorName: '',
      setting: {
        backups: true,
        batchremove: true,
      },

      updateVisible: false,

      overViewFlag: false,

      crumbA: '',
      crumbB: '',
      crumbC: '',
      sizeFromSet: 10,
      props: {
        //级联选择器懒加载
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          console.log(level, node);
          if (level == 0) {
            that.getAllCoal((list1) => {
              let arr = list1.map((e) => ({ value: e.id, label: e.coal }));
              resolve(arr); // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            });
          }
          if (level == 1) {
            let { value } = node;
            console.log(value);
            that.getSystem(value, (list2) => {
              console.log(list2);
              if (list2.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                }]
                resolve(arr);
              } else {
                let arr = list2.map((e) => ({ value: e.id, label: e.name }));
                resolve(arr);
              }
            });
          }
          if (level == 2) {
            let { value } = node;
            console.log(value);
            that.getSubSystem(value, (list3) => {
              console.log(list3);
              if (list3.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                  leaf: true,
                }]
                resolve(arr);
              } else {
                let arr = list3.map((e) => ({
                  value: e.id,
                  label: e.name,
                  leaf: true,
                }));
                resolve(arr);
              }
            });
          }
        },
      },
    };
  },
  mounted() {
    //获取session中的user的id和name
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;
    //根据名字查询系统设置中的结果
    this.getRequest(
        "/systemSetting/getSettingInfoByName/?name=" + this.name
    ).then((resp) => {
      if (resp.data) {
        console.log(this.name);
        console.log(resp.data);
        this.setting.backups = resp.data.backups;
        this.setting.batchremove = resp.data.batchremove;
        this.sizeFromSet = resp.data.numberperpage;
        this.sizeFromSet = resp.data.numberperpage;
        this.size = this.sizeFromSet
      }
    });
    if(this.global.subSystem_id === 0) {
      this.initData_()
    } else {
      this.initDataWithGlobalVar()
    }
    // if (this.$route.query.hasOwnProperty("subSystemId")) {
    //   this.handleJump();
    // } else {
    //   this.initData();
    // }
  },
  components: {
    OverViewOfSubSystem,
    addEquipment,
    coalOverView,
    updateEquipment
  },
  methods: {
    currentChange(currentPage) {
      this.page = currentPage;
      this.initDataWithGlobalVar()
      // if(this.$route.query.hasOwnProperty("subSystemId")) {
      //   if (this.$refs["cascade"].checkedValue === null) {
      //     this.handleJump()
      //   } else {
      //     this.handleChange()
      //   }
      // } else {
      //   this.handleChange();
      // }
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initDataWithGlobalVar()
      // if(this.$route.query.hasOwnProperty("subSystemId")) {
      //   if (this.$refs["cascade"].checkedValue === null) {
      //     this.handleJump()
      //   } else {
      //     this.handleChange()
      //   }
      // } else {
      //   this.handleChange();
      // }
    },
    resetSizePage() {
      this.size = this.sizeFromSet
      this.page = 1
    },
    initData_() {
      if (this.global.coal_id === 0) {
        this.getRequest("/dataList/getAllCoal/").then((resp) => {
          if (resp.data) {
            // 处理全局变量
            this.$changeGlobalVar(this.global.MODE_COAL, resp.data[0].id, resp.data[0].coal)
            // 处理面包屑
            this.crumbA = this.global.coal_name
          }
        }).then(() => {
          this.getRequest(
              "/coalManage/getSystemInfoByCoalId/?coalId=" + this.global.coal_id
          ).then((resp) => {
            if (resp.data) {
              // 处理全局变量
              this.$changeGlobalVar(this.global.MODE_SYSTEM, resp.data[0].id, resp.data[0].name)
              // 处理面包屑
              this.crumbB = this.global.system_name
              console.log("check", this.global.system_id)
              this.getRequest(
                  "/coalManage/getSubSystemInfoBySystemId/?systemId=" + this.global.system_id
              ).then((resp) => {
                if (resp.data) {
                  console.log("check", resp.data)
                  // 处理全局变量
                  this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, resp.data[0].id, resp.data[0].name)
                  // 处理面包屑
                  this.crumbC = this.global.subSystem_name
                  this.initDataWithGlobalVar()
                }
              })
            }
          })
        })
      }else {
        if (this.global.system_id === 0) {
          this.getRequest(
              "/coalManage/getSystemInfoByCoalId/?coalId=" + this.global.coal_id
          ).then((resp) => {
            if (resp.data) {
              // 处理全局变量
              this.$changeGlobalVar(this.global.MODE_SYSTEM, resp.data[0].id, resp.data[0].name)
              // 处理面包屑
              this.crumbB = this.global.system_name
            }
          }).then(() => {
            this.getRequest(
                "/coalManage/getSubSystemInfoBySystemId/?systemId=" + this.global.system_id
            ).then((resp) => {
              if (resp.data) {
                // 处理全局变量
                this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, resp.data[0].id, resp.data[0].name)
                // 处理面包屑
                this.crumbC = this.global.subSystem_name
              }
            }).then(() => {
              this.initDataWithGlobalVar()
            })
          })
        } else {
          if (this.global.subSystem_id === 0) {
            this.getRequest(
                "/coalManage/getSubSystemInfoBySystemId/?systemId=" + this.global.system_id
            ).then((resp) => {
              if (resp.data) {
                // 处理全局变量
                this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, resp.data[0].id, resp.data[0].name)
                // 处理面包屑
                this.crumbC = this.global.subSystem_name
              }
            }).then(() => {
              this.initDataWithGlobalVar()
            })
          }
        }
      }
    },
    initDataWithGlobalVar() {
      this.dataListLoading = true
      // 处理面包屑
      this.crumbA = this.global.coal_name
      this.crumbB = this.global.system_name
      this.crumbC = this.global.subSystem_name
      this.getRequest(
          "/equipmentManage/getEquipmentInfoBySubSystemId?subSystemId=" +
          this.global.subSystem_id + "&page=" + this.page + "&size=" + this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    handleSearch() {
      this.resetSizePage()
      if (this.$refs["cascade"].checkedValue === null ||this.$refs["cascade"].checkedValue.length == 0) {
        this.initDataWithGlobalVar()
      } else {
        //处理面包屑
        this.crumbA = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[0].label
        this.crumbB = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[1].label
        this.crumbC = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[2].label

        // 处理全局变量
        this.$changeGlobalVar(this.global.MODE_COAL, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[0].value, this.crumbA)
        this.$changeGlobalVar(this.global.MODE_SYSTEM, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[1].value, this.crumbB)
        this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[2].value, this.crumbC)

        this.initDataWithGlobalVar()
      }
    },
    handleCheck(row) {
      this.global.equipment_id = row.id
      this.global.equipment_name = row.name
      this.$router.push({
        name: 'coalSystemManage-subDeviceManage',
      })
      // this.$router.push({
      //   name: 'coalSystemManage-subDeviceManage',
      //   query: {
      //     equipmentId: row.id
      //   }
      // });
    },

    initData() {
      this.dataListLoading = true
      this.getRequest(
          "/equipmentManage/getEquipmentInfoByPage/?page=" +
          this.page +
          "&size=" +
          this.size + "&userId=" + this.currOperator
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
      // this.getRequest("/coalSystemManage/getSystemOverview").then((resp) => {
      //   if (resp.data) {
      //     this.options[0].num = resp.data.datasize + "G";
      //     this.options[1].num = resp.data.systemCount + "个";
      //     this.options[2].num = resp.data.subSystemCount + "个";
      //     this.options[3].num = resp.data.equipmentCount + "个";
      //   }
      // });
    },
    async getAllCoal(callback) {
      this.getRequest("/dataList/getAllCoal/").then((resp) => {
        if (resp.data) {
          callback(resp.data);
          console.log(resp.data);
        }
      });
    },
    async getSystem(coalId, callback) {
      this.getRequest(
          "/coalManage/getSystemInfoByCoalId?coalId=" + coalId
      ).then((resp) => {
        if (resp.data) {
          console.log(resp.data);
          callback(resp.data);
        }
      });
    },
    async getSubSystem(systemId, callback) {
      this.getRequest(
          "/coalManage/getSubSystemInfoBySystemId?systemId=" + systemId
      ).then((resp) => {
        if (resp.data) {
          console.log(resp.data);
          callback(resp.data);
        }
      });
    },
    handleChange() {
      this.dataListLoading = true
      if (this.$refs["cascade"].checkedValue === null ||this.$refs["cascade"].checkedValue.length == 0) {
        this.getRequest(
            "/equipmentManage/getEquipmentInfoByPage/?page=" +
            this.page +
            "&size=" +
            this.size + "&userId=" + this.currOperator
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      } else {
        this.getRequest(
            "/equipmentManage/getEquipmentInfoBySubSystemId?subSystemId=" +
            this.$refs["cascade"].getCheckedNodes()[0].value + "&page=" + this.page + "&size=" + this.size
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      }
    },
    handleJump() {
      this.dataListLoading = true;
      this.getRequest(
          "/equipmentManage/getEquipmentInfoBySubSystemId?subSystemId=" +
          this.$route.query.subSystemId + "&page=" + this.page + "&size=" + this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    addEquipment() {
      this.equipmentDialog = true;
      this.$nextTick(() => {
        this.$refs.addEquipment.init();
      });
    },
    update() {
      this.initDataWithGlobalVar();
    },
    // 复选框
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 删除设备
    deleteEquipment(id) {
      let ids = "?";
      this.$confirm(
          `确定对设备进行[${id ? "删除" : "批量删除"}]操作?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            if (id) {
              this.deleteSubEquipment(id);
              ids = "?ids=" + id;
            } else {
              this.dataListSelections.forEach((item) => {
                // 先删除所有的附属设备
                this.deleteSubEquipment(item.id);
                ids += "ids=" + item.id + "&";
              });
            }
            this.deleteRequest("/equipmentManage/deleteEquipment/" + ids).then(
                (resp) => {
                  if (resp.data) {
                    //this.handleChange();
                    this.initDataWithGlobalVar();
                  }
                }
            );
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    // 删除附属设备
    async deleteSubEquipment(id) {
      let ids = "?";
      let subEquipment = await this.getRequest(
          "/subEquipmentManage/getSubEquipmentInfoByEquipmentId?equipmentId=" + id
      );
      if (subEquipment.length) {
        subEquipment.forEach((item) => {
          // 先删除附属设备的所有数据项
          this.deleteMeasurePoint(item.id);
          ids += "ids=" + item.id + "&";
        });
        await this.deleteRequest(
            "/subEquipmentManage/deleteSubEquipment/" + ids
        );
      }
    },
    // 删除附属设备下的所有数据项
    async deleteMeasurePoint(id) {
      let ids = "?";
      let measurePoint = await this.getRequest(
          "/measurePointManage/getMeasurePointInfoBySubEquipmentId?subEquipmentId=" +
          id
      );
      if (measurePoint.length) {
        measurePoint.forEach((item) => {
          ids += "ids=" + item.id + "&";
        });
        await this.deleteRequest(
            "/measurePointManage/deleteMeasurePoint/" + ids
        );
      }
    },
    updateHandle (row) {
      this.updateVisible = true
      this.$nextTick(() => {
        this.$refs.updateEquipment.init(row)
      })
    },
  },
};
</script>

<style scoped>
</style>
